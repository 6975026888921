body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "JDME_thin"; /* Nommez la police selon votre choix */
  src: url("https://static.wixstatic.com/ufonts/55ea8c_050758a6a65f4d0bbfe0c24fc6284a3a/woff2/file.woff2")
    format("woff2"); /* L'URL de votre fichier de police */
  font-weight: normal;
  font-style: normal;
}
