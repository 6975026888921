@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "JDME"; /* Nommez la police selon votre choix */
  src: url("https://static.wixstatic.com/ufonts/55ea8c_2184c055e3c9424d93deb469bee21fec/woff2/file.woff2")
    format("woff2"); /* L'URL de votre fichier de police */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JDME_regular"; /* Nommez la police selon votre choix */
  src: url("https://static.wixstatic.com/ufonts/55ea8c_f108fcc253134effac0cecc7c89e3c90/woff2/file.woff2")
    format("woff2"); /* L'URL de votre fichier de police */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JDME_thin"; /* Nommez la police selon votre choix */
  src: url("https://static.wixstatic.com/ufonts/55ea8c_050758a6a65f4d0bbfe0c24fc6284a3a/woff2/file.woff2")
    format("woff2"); /* L'URL de votre fichier de police */
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: f2f4f6;
}
